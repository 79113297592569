/* global Component, axios */
class profilePageB2C extends Component {

    static name() {
        return "profilePage";
    }

    static componentName() {
        return "profilePage";
    }

    data() {
        return {
            editAvatar:false,
            activeView:"Orders",
            showReportSubMenu:false,
            response: null,
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            errorAlertDataSession: null,
            errorAlert: null,
            listMode: true,
            loadingImage: false
        };
    }

    getWatch() {
        return {
            "session":function (){
                if(this.session) {
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Province', value: this.session.fields.ProvinceCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Country', value: this.session.fields.Country});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_ZipCode', value: this.session.fields.ZipCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_CenterCost', value: this.session.fields.CenterCost});
                }
            },
            "$route.params.activeView":function (newValue,oldValue){
                if(oldValue != newValue)
                    this.changeView(newValue);

            }
        };
    }

    created() {
        return function () {
            if(this.$route.params.activeView){
                this.activeView = this.$route.params.activeView;
            }
        };
    }

    mounted() {
        return function () {
            this.subscribeEvent('profileSaveB2C',this.editProfileSession);
        };
    }

    updated() {
        return function () {
            if(this.$store.getters.getAppState === "ready" && !this.$store.getters.getIsLogin) {
                this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                this.$router.push("login");
            }
        };
    }

    getComputed() {
        return {
            showInvoiceListInProfile(){
                return this.$store.getters.getSetting.ShowInvoiceListInProfile;
            },
            stringPattern:function (){
                return this.$store.getters.getPhoneMaskPattern;
            },
            phonePattern: function (){
                return new RegExp(this.stringPattern);
            },
            orderSerNr:function (){
                return this.$route.params.SerNr;
            },
            viewmode: function (){
                if(this.$store.getters.getUserIsAdmin)
                    return 'alls-persons';
                return 'active-persons';
            },
            session: function () {
                let storeSession = this.$store.getters.getSession;
                if(storeSession)
                    return storeSession;
                return null;
            },
            salesman: function () {
                return this.$store.getters.getSalesMan;
            },
            customer: function () {
                return this.$store.getters.getCustomer;
            },
            isAdmin: function () {
                return this.$store.getters.getUserIsAdmin;
            },
            avatarUrl: function () {
                if(this.$store.getters.getAvatar)
                    return this.$store.getters.getAvatar;
                else return "../static/ecommerce/img/user.png";
            },
            customClass:function (){
                return [];
            }
        };
    }

    clearFile(){
        const fileInput = document.querySelector('#avatarFile');
        fileInput.value = '';
        this.editAvatar = false;
    }

    resetDefault(){
        // eslint-disable-next-line no-alert
        if (confirm(this.tr('Do you want to reset your profile image to default?'))){
            axios.post("/ecommerce/api/resetAvatar/" + this.session.fields.Code, {reset:true})
                .then((response)=>{
                    this.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image changed`,type: 'info'});
                    this.clearFile();
                })
                .catch((error)=>{
                    this.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image was't changed`,type: 'error'});
                    this.clearFile();
                    // eslint-disable-next-line no-console
                    console.log('FAILURE!!',error);
                });
        }
    }

    submitFile(){
        let self = this;
        let formData = new FormData();
        let imagefile = document.querySelector('#avatarFile');
        if(imagefile.files.length > 0){
            this.loadingImage = true;
            formData.append("image", imagefile.files[0]);
            axios.post("/ecommerce/api/changeAvatar/" + this.session.fields.Code, formData,{headers: {'Content-Type': 'multipart/form-data'}})
                .then((response)=>{
                    self.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image changed`,type: 'info'});
                    self.clearFile();
                    self.$store.dispatch('updateAvatar',response.data.newURL);
                    self.loadingImage = false;
                })
                .catch((error)=>{
                    self.$store.dispatch('showNotificacion',{title:"Information", message: `Profile Image was't changed`,type: 'error'});
                    self.clearFile();
                    self.loadingImage = false;
                    // eslint-disable-next-line no-console
                    console.log('FAILURE!!',error);
                });
        }
        else{
            this.$store.dispatch('showNotificacion',{title:"Information", message:`Please select an Image`,type: 'info'});
        }
    }

    clearAvatar(e, key) {
        e.preventDefault();
        e.stopPropagation();
        this.avatarData = null;
    }

    changeView(newactiveView){
        this.activeView = newactiveView;
    }

    activeReport(reportName){
        this.showReportSubMenu = false;
        this.activeView = "Reports";
        this.reportName = reportName;
        setTimeout(()=>this.emitEvent('changeReportView',reportName),500);
    }

    logout() {
        let self = this;
        this.$store.dispatch("logout").then(()=>{
            document.cookie = "connect.ecommerce.sid" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            self.$router.push("/home");
        });
    }

    updateSelectValues(){
        this.session.fields.ProvinceCode = this.$store.getters.getSelectLinkToValue('Session_Province');
        this.session.fields.ZipCode = this.$store.getters.getSelectLinkToValue('Session_ZipCode');
        this.session.fields.Country = this.$store.getters.getSelectLinkToValue('Session_Country');
        this.session.fields.DefaultCenterCost = this.$store.getters.getSelectLinkToValue('Session_CenterCost');
    }

    loadSelects() {
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_Province', value: this.session.fields.ProvinceCode});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_Country', value: this.session.fields.Country});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_ZipCode', value: this.session.fields.ZipCode});
        this.$store.dispatch('updateSelectLinkto', {table: 'Session_CenterCost', value: this.session.fields.DefaultCenterCost});
    }

    editProfileSession(session) {
        this.errorAlertDataSession = null;

        if (!this.session.fields.Name)
            this.errorAlertDataSession = 'Please fill the field Name';
        else {
            if (!this.session.fields.LastName)
                this.errorAlertDataSession = 'Please fill the field Last Name';
        }

        if (!this.session.fields.Phone)
            this.errorAlertDataSession = 'Please fill the field Last Name';
        else {
            if (!this.phonePattern.test(this.session.fields.Phone))
                this.errorAlertDataSession = ['Incorrect Format {{$1}}', "Phone"];
            else if (!/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/g.test(this.session.fields.Email))
                this.errorAlertDataSession = ['FORMATVALUEERR', "Email"];
        }

        if (!this.errorAlertDataSession) {
            axios.post('/ecommerce/api/updatePerson',this.session)
                .then(response => {
                    this.response = response;
                    if (response.data.success) {
                        this.$store.dispatch('showNotificacion',{title: 'Guardar', message: 'Datos Personales guardados',type: 'success'});
                    }
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log('Error response in Register Person', error);
                });
        }
    }

    async changePassword() {
        this.errorAlert = null;
        if (!this.oldPassword)
            this.errorAlert = 'Please type your actual password';
        else if (!this.newPassword)
            this.errorAlert = 'Please type a new password';
        else if (!this.confirmPassword)
            this.errorAlert = 'Please confirm the new password';
        else if (this.newPassword !== this.confirmPassword)
            this.errorAlert = "The Passwords does'nt match";

        if(this.newPassword.length < 6)
            this.errorAlert = ["Password length must be greater than {{$1}}","5"];

        let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        if(!strongRegex.test(this.newPassword) && !mediumRegex.test(this.newPassword))
            this.errorAlert = "Password must include uppercase, lowercase and number";

        if (!this.errorAlert) {
            let data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
            };

            await axios.post('/ecommerce/api/changePassword', data)
                .then(response => {
                    this.response = response;
                });

            if (!this.response.data.success) {
                this.errorAlert = this.response.data.errors;
                this.oldPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
            } else {
                this.oldPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
                this.$store.dispatch('showNotificacion', {
                    title: 'Cambio de Password',
                    message: 'Password cambiado satisfactoriamente',
                    type: 'success'
                });
            }
        }
    }

    getMethods() {
        return {
            clearFile: this.clearFile,
            resetDefault: this.resetDefault,
            submitFile: this.submitFile,
            clearAvatar: this.clearAvatar,
            changeView: this.changeView,
            activeReport: this.activeReport,
            logout: this.logout,
            updateSelectValues:this.updateSelectValues,
            loadSelects : this.loadSelects,
            editProfileSession: this.editProfileSession,
            changePassword: this.changePassword
        };
    }

    getTemplate() {
        return `<div class="profile-view">
                    <headerSectionComponent headerTitle="Actualización de Datos" :renderMenu="false" :renderSearch="false"></headerSectionComponent>
                    <div class="section white" >
                        <template v-if="!isLoading">
                            <div style="min-height: 36.5rem;">
                                <section class="padding-small">
                                    <div class="container-fluid" :class="customClass">
                                        <div class="row">
                                            <div class="customer-sidebar col-xl-2 col-lg-3 mb-md-5 text-sm-left">
                                                <div class="customer-sidebar-content col-12">
                                                    <a class="navbar-brand col-12" href="#"><h5 class="title">{{tr('Your profile')}}</h5></a>
                                                    <div class="list-group" id="navbarText">
                                                        <a class="list-group-item list-group-item-action" data-toggle="list" href="#/profile" @click="logout">
                                                            <span><i class="icon fas fa-sign-out-alt"></i> {{tr('Log Out')}}</span>
                                                        </a>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Orders"}' data-toggle="list" to="/profile/Orders" @click2="changeView('Orders')">{{tr("Orders")}}</router-link>
                                                        <router-link v-if="showInvoiceListInProfile" class="list-group-item list-group-item-action" :class='{active: activeView=="Invoices"}' data-toggle="list" to="/profile/Invoices" @click2="changeView('Invoices')">{{tr("Invoices")}}</router-link>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Addresses"}' data-toggle="list" to="/profile/Addresses" @click2="changeView('Addresses')" >{{tr('Addresses')}}</router-link>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="Profile"}' data-toggle="list" to="/profile/Profile" @click2="changeView('Profile')" >{{tr('Profile')}}</router-link>
                                                        <router-link class="list-group-item list-group-item-action" :class='{active: activeView=="ChangePassword"}' data-toggle="list" to="/profile/ChangePassword" @click2="changeView('ChangePassword')">{{tr('Change Password')}}</router-link>
                                                    </div>
                                                    <div class="col-12 row justify-content-center m-0 p-0 pr-1">
                                                        <template v-if="loadingImage">
                                                            <div class="avatar-container py-5">
                                                                <i class="fas fa-spinner fa-spin fa-2x"></i>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="avatar-container">
                                                                <img :src="avatarUrl" alt="Avatar Profile" class="rounded rounded-circle d-block custom-avatar" />
                                                                <span v-if="!editAvatar" class="edit-button" @click="editAvatar=true" :title="tr('Edit')" ><i class="fa fa-edit"></i></span>
                                                            </div>
                                                        </template>
                                                        <div class="change-avatar col-12 mt-1 p-2" v-if="editAvatar">
                                                            <div class="form-group mb-2">
                                                                <span class="close-button" :title="tr('Cancel')" @click="clearFile"><i class="fas fa-times"></i></span>
                                                                <div class="custom-file">
                                                                    <input type="file" id="avatarFile" class="file-input" hidden :lang="$store.state.language" @change="submitFile">
                                                                    <label for="avatarFile" class="custom-file-label" :data-browse="tr('Change Avatar')" :lang="$store.state.language">{{tr("Change Avatar")}}</label>
                                                                </div>
                                                            </div>
                                                            <!--<div class="form-group text-center mb-0">
                                                                <button class="btn btn-secondary" :title="tr('Reset Image')" @click="resetDefault"><i class="fa fa-redo"></i></button>
                                                            </div>-->
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0 text-center text-lg-left">
                                                        <p class="name">{{session.fields.Name}} {{session.fields.LastName}}</p>
                                                        <p class="customer">{{tr('Customer')}}: <span>{{customer.Name}}</span></p>
                                                        <p class="text-muted text-small" v-if="salesman">
                                                            {{tr("Your account executive is")}}:<br>
                                                            {{salesman.Name}} {{salesman.LastName}} <br>
                                                            {{salesman.Phone}}<br>
                                                            {{salesman.Email}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-10 col-lg-9 pl-lg-3 mt-3">
                                                <div class="tab-content p-1 p-md-3">
                                                    <div v-if="activeView=='Addresses'" id="AddressesView" >
                                                        <addressesManagerComponent> </addressesManagerComponent>
                                                    </div>
                                                    <div v-if="activeView=='Orders'" id="OrderView" >
                                                        <orderViewComponent orderId="orderSerNr"> </orderViewComponent>
                                                    </div>
                                                    <div v-if="activeView=='Invoices'" id="InvoiceView" >
                                                        <invoice-list />
                                                    </div>
                                                    <div v-if="activeView=='ChangePassword'" id="ChangePassword" >
                                                        <div class="col-sm-12">
                                                            <div ref='errorChangePassword' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorAlert">
                                                                <strong>{{tr(errorAlert)}}!</strong>
                                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorAlert = ''">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="content-block">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_old" class="form-label">{{tr('Old Password')}}</label>
                                                                        <input id="password_old" type="password" class="form-control" v-model="oldPassword">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_1" class="form-label">{{tr('New Password')}}</label>
                                                                        <input id="password_1" type="password" class="form-control" v-model="newPassword">
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="password_2" class="form-label">{{tr('Retype New Password')}}</label>
                                                                        <input id="password_2" type="password" class="form-control" v-model="confirmPassword">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- /.row-->
                                                            <div class="text-right">
                                                                <button class="btn btn-custom change-password" @click.prevent="changePassword"><span class="icon"><i class="fa-icon fa fa-save"></i></span> {{tr('Change Password')}}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div  v-if="activeView=='Profile'" id="Profile" class="py-2" >
                                                        <editProfileB2C :errorMsg="errorAlertDataSession" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row">
                                <div class="loading" style="min-height: 36.5rem;">
                                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

profilePageB2C.registerComponent();
